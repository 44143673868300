import capaArteDaGuerra from "../_assets/booksCapa/arteDaGuerra.jpg";
import livroArteDaGuerra from "../_assets/booksPdf/arteDaGuerra.pdf";
const books = [
	{
		id: 1,
		capa: capaArteDaGuerra,
		livro: livroArteDaGuerra,
	},
	{
		id: 2,
		capa: capaArteDaGuerra,
		livro: livroArteDaGuerra,
	},
	{
		id: 3,
		capa: capaArteDaGuerra,
		livro: livroArteDaGuerra,
	},
];
export { books };
