import NavigationItem from "./NavigationItem";

const Navigation = () => {
	return (
		<>
			<div className='navbar navbar-expand-lg navbar-light navigation-bar'>
				<div className='container-fluid'>
					<button
						className='navbar-toggler mb-2'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon'></span>
					</button>
					<div
						className='collapse navbar-collapse'
						id='navbarSupportedContent'>
						<ul className='navbar-nav ms-auto me-auto mb-2 mb-lg-0 gap-2'>
							<NavigationItem
								label={"A História do Braille"}
								url={"/"}
							/>
							<NavigationItem
								label={"O Braille de Ontem"}
								url={"/brailleDeOntem"}
							/>
							<NavigationItem
								label={"Exposição"}
								url={"/exposicao"}
							/>
							<NavigationItem
								label={"Oficina de Livros táteis"}
								url={"/oficina"}
							/>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};
export default Navigation;
