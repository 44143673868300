import { books } from "../../data/books";
const BookList = () => {
	return (
		<>
			{books.map((book) => (
				<img className='m-2' src={book.capa} width={"100px"} alt='' />
			))}
		</>
	);
};
export default BookList;
