import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Home from "./pages/Home";
import BrailleDeOntem from "./pages/BrailleDeOntem";
import Exposicao from "./pages/Exposicao";
import Ofinica from "./pages/Oficina";

const router = createBrowserRouter([
	{
		element: <App />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/brailleDeOntem",
				element: <BrailleDeOntem />,
			},
			{
				path: "/exposicao",
				element: <Exposicao />,
			},
			{
				path: "/oficina",
				element: <Ofinica />,
			},
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
