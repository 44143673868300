const Exposicao = () => {
	return (
		<>
			<div>
				<h1 style={{ color: "#ffff" }}>Página Exposição</h1>
			</div>
		</>
	);
};

export default Exposicao;
