const BookDisplay = (props) => {
	const livro = props.book;
	return (
		<div className='p-5'>
			<object
				className='book-display'
				data={livro}
				type='application/pdf'>
				<p>Seu navegador não tem um plugin pra PDF</p>
			</object>
		</div>
	);
};
export default BookDisplay;
