import BookDisplay from "../../components/BookDisplay";
import BookList from "../../components/BooksList";
import livroArteDaGuerra from "../../_assets/booksPdf/arteDaGuerra.pdf";

const Home = () => {
	return (
		<>
			<div className='row'>
				<div className='col-md-2 p-5'>
					<BookList />
				</div>
				<div className='col-md-8'>
					<BookDisplay book={livroArteDaGuerra} />
				</div>
				<div className='col-md-2'>Janela de Libras</div>
			</div>
		</>
	);
};

export default Home;
