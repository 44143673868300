const Ofinica = () => {
	return (
		<>
			<div>
				<h1 style={{ color: "#ffff" }}>
					Página Oficina de Livros táteis
				</h1>
			</div>
		</>
	);
};

export default Ofinica;
